import firebase from "firebase";
import TitleComum from "./componentesCadastro/TitleComum";
import CadastroCategoria from "./componentesCadastro/CadastroCategoria";
import ListaCategorias from "./componentesCadastro/ListaCategorias";
import ListaProdutos from "./componentesCadastro/ListaProdutos";
import CadastroProdutoGpt from "./componentesCadastro/CadastroProdutoGpt";
import CadastroCategoriaGpt from "./componentesCadastro/CadastroCategoriaGpt";

const Cadastrar = () => {
  const db = firebase.firestore();

  return (
    <div className="container_comum">
      <TitleComum name="Cadastro de Produtos" icon="bi bi-speedometer" />
      <div className="content_comum">
        <div className="row_comum">
          <div className="col_comum">
            <CadastroProdutoGpt db={db} />
            <ListaProdutos db={db} />
            <CadastroCategoriaGpt db={db} />
            <ListaCategorias db={db} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cadastrar;
