import { useState, useEffect } from "react";

const ListaCategorias = ({ db }) => {
  const [categoria, setCategoria] = useState([]);

  useEffect(() => {
    db.collection("categorias")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setCategoria(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const setDisponivel = (e, par) => {
    e.preventDefault();
    db.collection("categorias").doc(par).update({
      disponibilidade: false,
    });
  };
  const setIndisponivel = (e, par) => {
    e.preventDefault();
    db.collection("categorias").doc(par).update({
      disponibilidade: true,
    });
  };

  return (
    <div className="card_cadastro" style={{ background: "#b8d5ff" }}>
      <div style={{ display: "block" }}>
        <h2>Categorias Cadastradas</h2>
        <div className="overflow mxh250">
          <table>
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Linhas</th>
                <th>Disp</th>
              </tr>
            </thead>
            <tbody>
              {categoria.map((val) => {
                return (
                  <tr key={val.id}>
                    <td>{val.data.nomeCategoria}</td>
                    <td>
                      {val.data.linha01}
                      <br />
                      {val.data.linha02}
                    </td>
                    {val.data.disponibilidade === true ? (
                      <td
                        onClick={(e) => setDisponivel(e, val.id)}
                        className="disponibilidade"
                        style={{ color: "#21bc32" }}
                      >
                        <i className="bi bi-check-circle-fill"></i>
                      </td>
                    ) : (
                      <td
                        onClick={(e) => setIndisponivel(e, val.id)}
                        className="disponibilidade"
                      >
                        <i
                          className="bi bi-x-octagon-fill"
                          style={{ color: "#bf2120" }}
                        ></i>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListaCategorias;
