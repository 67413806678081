import { auth, provider } from "./../firebase.js";
import firebase from "firebase";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  HashRouter,
} from "react-router-dom";
import Logon from "./../pages/Logon.js";
import AccessDanied from "../pages/AccessDanied.js";
import Header from "./../layout/Header";
import Footer from "./../layout/Footer";
import Home from "./../pages/Home";
import Cadastrar from "../pages/Cadastrar";
import Clientes from "../pages/Clientes";
import Usuario from "../pages/Usuario.js";
import Entregues from "../pages/Entregues.js";
import Recusados from "../pages/Recusados.js";
import Aside from "../layout/Aside.js";
import Estoque from "../pages/Estoque.js";
import Comercial from "../pages/Comercial.js";
import Despesas from "../pages/Despesas.js";
import Editar from "../pages/Editar.js";

const PandaRouter = () => {
  const db = firebase.firestore();
  const [dados, setDados] = useState([]);
  const [login, setLogin] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((val) => {
      if (val != null) {
        setLogin({
          email: val.email,
          uid: val.uid,
        });
        db.collection("users")
          .doc(val.uid)
          .collection("cadastro")
          .onSnapshot(function (snapshot) {
            setDados(
              snapshot.docs.map(function (document) {
                return { id: document.id, data: document.data() };
              })
            );
          });
      }
    });
  }, []);

  function logar(e) {
    e.preventDefault();
    //parametros
    let email = document.getElementById("email-login").value;
    let senha = document.getElementById("senha-login").value;

    auth
      .signInWithEmailAndPassword(email, senha)
      .then((auth) => {
        setLogin(auth.user.email);
        window.location.href = "/";
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  function deslogar(e) {
    e.preventDefault();
    auth.signOut().then(function () {
      window.location.href = "/";
    });
  }

  const loginGoogle = (e) => {
    e.preventDefault();
    alert("logando com Google");
    auth.signInWithPopup(provider).then((result) => {
      if (result) {
        setLogin(result.user.email);
      }
    });
  };

  /*var usuarioAtual = dados.filter( dados=> dados.data.email === email )
    if(usuarioAtual.length > 0){
        var userId = usuarioAtual[0].id;
    }else{
        userId = 'XXXXXXXX' 
    }*/

  var kindOfCad = dados.length > 0 ? dados[0].data.typeOfCad : ["nothing"];

  /*if(cadastro.length > 0){
        var kindOfCad = cadastro[0].data.typeOfCad
       }else{
        var kindOfCad = null
       }*/

  return (
    <div>
      {login ? (
        <div>
          {kindOfCad !== "admin" ? (
            <AccessDanied deslogar={deslogar} />
          ) : (
            <div>
              <HashRouter>
                <Header db={db} />
                <Aside />
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/cadastrar" element={<Cadastrar />} />
                  <Route exact path="/clientes" element={<Clientes />} />
                  <Route exact path="/clientes/:id" element={<Usuario />} />
                  <Route exact path="/cadastrar/:id" element={<Editar />} />
                  <Route
                    exact
                    path="/pedidos_entregues"
                    element={<Entregues />}
                  />
                  <Route
                    exact
                    path="/pedidos_recusados"
                    element={<Recusados />}
                  />
                  <Route exact path="/estoque" element={<Estoque />} />
                  <Route
                    exact
                    path="/controle-comercial"
                    element={<Comercial />}
                  />
                  <Route exact path="/despesas" element={<Despesas />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer deslogar={deslogar} />
              </HashRouter>
              <div style={{ height: "70px" }}></div>
            </div>
          )}
        </div>
      ) : (
        <Logon logar={logar} loginGoogle={loginGoogle} />
      )}
    </div>
  );
};

export default PandaRouter;
