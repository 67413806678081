import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import { storage } from "./../../firebase";
import firebase from "firebase";

const CadastroCategoriaGpt = ({ db }) => {
  //Redutor de imagens
  const [selectedImage, setSelectedImage] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);

  useEffect(() => {
    if (selectedImage !== null) {
      const handleImageResizeAndUpload = async () => {
        try {
          const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedImage = await imageCompression(
            selectedImage,
            options
          );

          const formData = new FormData();
          formData.append("image", compressedImage);

          setResizedImage(compressedImage);
          console.log("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      handleImageResizeAndUpload();
    }
  }, [selectedImage]);

  console.log(selectedImage);

  useEffect(() => {}, []);

  const cadastrarCategoria = (e) => {
    e.preventDefault();
    let categoria = document.getElementById("nameCategoria").value;
    let linha01 = document.getElementById("linha01").value;
    let linha02 = document.getElementById("linha02").value;
    let direction = categoria
      .toLowerCase()
      .replace(/\s+/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    var storageRef = firebase.storage().ref();
    let nameRenamed = uuidv4() + resizedImage.name;

    if (
      resizedImage.type === "image/jpg" ||
      resizedImage.type === "image/png" ||
      resizedImage.type === "image/jpeg"
    ) {
      if (resizedImage.size < 600000) {
        const uploadTask = storageRef
          .child("images_categorias/" + nameRenamed)
          .put(resizedImage);

        uploadTask.on(
          "state_changed",
          function (snapshot) {
            //document.getElementById("btn_cadastrar").disabled = true;
          },
          function (error) {
            alert(error.message);
          },
          function () {
            storage
              .ref("images_categorias")
              .child(nameRenamed)
              .getDownloadURL()
              .then(function (url) {
                db.collection("categorias").add({
                  nomeCategoria: categoria,
                  linha01: linha01,
                  linha02: linha02,
                  slug: direction,
                  disponibilidade: true,
                  image: url,
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                });
                setSelectedImage(null);

                document.getElementById("nameCategoria").value = "";
                document.getElementById("linha01").value = "";
                document.getElementById("linha02").value = "";
                document.getElementById("imgSelect").value = "";
                document.getElementById("btn_cadastrar").disabled = false;

                alert("Categoria cadastrada com Sucesso!");
              });
          }
        );
      } else {
        alert("Tamanho da imagem excede o limite de 250K");
      }
    } else {
      alert("Tipo de imagem não suportado.");
    }
  };

  return (
    <div className="card_cadastro">
      <div>
        <h2>Cadastrar Categoria resizer</h2>
        <form onSubmit={(e) => cadastrarCategoria(e)}>
          <input
            type="text"
            name="name"
            placeholder="Nome da categoria..."
            id="nameCategoria"
          />
          <textarea placeholder="Linha 01..." id="linha01"></textarea>
          <textarea placeholder="Linha 02..." id="linha02"></textarea>
          <label htmlFor="imgSelectCategoria">Escolher arquivo</label>
          <input
            type="file"
            id="imgSelectCategoria"
            name="imgSelectCategoria"
            onChange={(e) => setSelectedImage(e.target.files[0])}
            required
          />
          <div className="content_btn">
            <button className="btn01" id="btn_cadastrar" type="submit">
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CadastroCategoriaGpt;
