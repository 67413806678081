import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import { storage } from "./../../firebase";
import firebase from "firebase";

const CadastroProdutoGpt = ({ db }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);

  const [file, setFile] = useState(null);
  const [categoria, setCategoria] = useState([]);
  const [slug, setSlug] = useState(null);
  const [lista, setLista] = useState("teste");

  useEffect(() => {
    let categoriaSelected = categoria.filter(
      (val) => val.data.nomeCategoria === lista
    );
    if (categoriaSelected.length > 0) {
      setSlug(categoriaSelected[0].data.slug);
    } else {
      setSlug(null);
    }
  }, [lista]);

  const putList = () => {
    let catValue = document.getElementById("categoria2").value;
    if (catValue !== "") {
      setLista(catValue);
    } else {
      setLista(null);
    }
  };

  useEffect(() => {
    if (selectedImage !== null) {
      const handleImageResizeAndUpload = async () => {
        try {
          const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };

          const compressedImage = await imageCompression(
            selectedImage,
            options
          );

          const formData = new FormData();
          formData.append("image", compressedImage);

          setResizedImage(compressedImage);
          console.log("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
      handleImageResizeAndUpload();
    }
  }, [selectedImage]);

  console.log(categoria);

  const cadastrarProduto = (e) => {
    e.preventDefault();
    let nomeProduto = document.getElementById("nomeProduto2").value;
    let descProduto = document.getElementById("descProduto2").value;
    let qtdMin = document.getElementById("qtdMin2").value;
    let valorProduto = parseFloat(
      document.getElementById("valorProduto2").value
    );
    var storageRef = firebase.storage().ref();
    let nameRenamed = uuidv4() + resizedImage.name;

    if (lista !== null) {
      if (
        resizedImage.type === "image/jpg" ||
        resizedImage.type === "image/png" ||
        resizedImage.type === "image/jpeg"
      ) {
        if (resizedImage.size < 600000) {
          const uploadTask = storageRef
            .child("images_produtos/" + nameRenamed)
            .put(resizedImage);

          uploadTask.on(
            "state_changed",
            function (snapshot) {
              document.getElementById("btn_cadastrar2").disabled = true;
            },
            function (error) {
              alert(error.message);
            },
            function () {
              storage
                .ref("images_produtos")
                .child(nameRenamed)
                .getDownloadURL()
                .then(function (url) {
                  db.collection("produtos").add({
                    nomeProduto: nomeProduto,
                    descProduto: descProduto,
                    qtdMin: qtdMin,
                    valorProduto: valorProduto,
                    categoria: lista,
                    disponibilidade: true,
                    slug: slug,
                    image: url,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                  });
                  setSelectedImage(null);

                  document.getElementById("nomeProduto2").value = "";
                  document.getElementById("descProduto2").value = "";
                  document.getElementById("qtdMin2").value = "";
                  document.getElementById("valorProduto2").value = "";
                  document.getElementById("imgSelect2").value = "";
                  document.getElementById("btn_cadastrar2").disabled = false;

                  alert("Produto cadastrado com Sucesso!");
                });
            }
          );
        } else {
          alert("Tamanho da imagem excede o limite de 250K");
        }
      } else {
        alert("Tipo de imagem não suportado.");
      }
    } else {
      alert("Selecione uma categoria válida.");
    }
  };

  useEffect(() => {
    db.collection("categorias")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setCategoria(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  return (
    <div className="card_cadastro">
      <div>
        <h2>Cadastro de Produtos Resized Images</h2>
        <form onSubmit={cadastrarProduto}>
          <input
            type="text"
            id="nomeProduto2"
            placeholder="Nome do Produto..."
          />
          <input
            type="text"
            id="descProduto2"
            placeholder="Descrição do produto..."
          />
          <input
            id="valorProduto2"
            type="number"
            min="0"
            max="100"
            step=".01"
            placeholder="Valor do produto R$..."
            style={{ marginRight: "4.2px", width: "calc(50% - 8px)" }}
          />
          <input
            type="number"
            id="qtdMin2"
            placeholder="Quantidade mínima..."
          />
          <div className="selecttipo">
            <select
              name="categoriasList"
              form="categoriasList"
              id="categoria2"
              onChange={putList}
              required
            >
              <option value="" defaultChecked>
                Tipo...
              </option>

              {categoria.map((val) => {
                return (
                  <option key={val.id} value={val.data.nomeCategoria}>
                    {val.data.nomeCategoria}
                  </option>
                );
              })}
            </select>
          </div>

          <label htmlFor="imgSelect2">Escolher arquivo</label>
          <input
            type="file"
            id="imgSelect2"
            onChange={(e) => setSelectedImage(e.target.files[0])}
            required
          />
          <div className="content_btn">
            <button className="btn01" type="submit" id="btn_cadastrar2">
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CadastroProdutoGpt;
