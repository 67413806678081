import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ListaProdutos = ({ db }) => {
  const navigate = useNavigate();

  const [produtos, setProdutos] = useState([]);
  useEffect(() => {
    db.collection("produtos")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setProdutos(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const setDisponivel = (e, par) => {
    e.preventDefault();
    db.collection("produtos").doc(par).update({
      disponibilidade: false,
    });
  };
  const setIndisponivel = (e, par) => {
    e.preventDefault();
    db.collection("produtos").doc(par).update({
      disponibilidade: true,
    });
  };

  const openDeleteModal = (par) => {
    let deleteModalBox = document.getElementById("delete_modal" + par);
    deleteModalBox.style.display = "flex";
  };

  const closeDeleteModal = (par) => {
    let deleteModalBox = document.getElementById("delete_modal" + par);
    deleteModalBox.style.display = "none";
  };

  const DeleteModalBox = ({ uid }) => {
    const delProduct = (e, uid) => {
      e.preventDefault();
      db.collection("produtos").doc(uid).delete();
    };
    return (
      <div className="delete_modal_box">
        <h3>Tem certeza que deseja deletar esse ítem?</h3>
        <div className="delete_modal_buttons">
          <button onClick={(e) => delProduct(e, uid)}>Sim</button>
          <button onClick={() => closeDeleteModal(uid)}>Não</button>
        </div>
      </div>
    );
  };

  return (
    <div className="card_cadastro" style={{ background: "#b8d5ff" }}>
      <div style={{ display: "block" }}>
        <h2>Produtos Cadastrados</h2>
        <div className="overflow mxh250">
          <table>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Categoria</th>
                <th>Descrição</th>
                <th>Disp</th>
                <th>Del</th>
                <th>Edt</th>
              </tr>
            </thead>
            <tbody>
              {produtos.map((val) => {
                return (
                  <tr key={val.id}>
                    <div className="delete_modal" id={"delete_modal" + val.id}>
                      <DeleteModalBox uid={val.id} />
                    </div>
                    <td>{val.data.nomeProduto}</td>
                    <td>{val.data.categoria}</td>
                    <td>{val.data.descProduto}</td>
                    {val.data.disponibilidade === true ? (
                      <td
                        onClick={(e) => setDisponivel(e, val.id)}
                        className="disponibilidade"
                        style={{ color: "#21bc32" }}
                      >
                        <i className="bi bi-check-circle-fill"></i>
                      </td>
                    ) : (
                      <td
                        onClick={(e) => setIndisponivel(e, val.id)}
                        className="disponibilidade"
                      >
                        <i
                          className="bi bi-x-octagon-fill"
                          style={{ color: "#bf2120" }}
                        ></i>
                      </td>
                    )}
                    <td className="delete">
                      <i
                        className="bi bi-trash3"
                        onClick={() => openDeleteModal(val.id)}
                      ></i>
                    </td>
                    <td className="edit">
                      <i
                        className="bi bi-pencil"
                        onClick={() => navigate("/cadastrar/" + val.id)}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListaProdutos;
