import firebase from "firebase";
import TitleComum from "./componentesCadastro/TitleComum";
import EditarProdutoGpt from "./componentesCadastro/EditarProdutoGpt";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Editar = () => {
  const db = firebase.firestore();
  const { id } = useParams();
  const [produtos, setProdutos] = useState([]);
  useEffect(() => {
    db.collection("produtos")
      .orderBy("timestamp")
      .onSnapshot(function (snapshot) {
        setProdutos(
          snapshot.docs.map(function (document) {
            return { id: document.id, data: document.data() };
          })
        );
      });
  }, []);

  const produto = produtos.filter((produto) => produto.id === id);

  const navigate = useNavigate();

  const goToBack = () => {
    navigate("/cadastrar");
  };

  return (
    <div className="container_comum">
      <TitleComum name="Editar Produto" icon="bi bi-pencil" />
      <div className="content_comum">
        <div className="row_comum">
          <div
            className="col_comum"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <EditarProdutoGpt db={db} produto={produto} goToBack={goToBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editar;
